<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-body">
                    <div class="top-img">
                        <img :src="gameInfo.logo" alt="">
                    </div>
                    <div class="top-title no-warp">
                        {{gameInfo.title}}
                    </div>
                    <div class="top-btn" @click="()=>{isPlay = false}">
                        PLAY
                    </div>  
                </div>              
            </div>
            <div class="gamewin" v-else>
                <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
            </div>
            <div class="details-description">
                <div class="des-top">Description :</div>
                <div class="des-body warp-6">{{gameInfo.synopsis}}</div>
            </div>
            <div class="cont-st">
                <div class="cont-st-h">
                    <!-- <img src="@/assets/slices/ic_all1.png" alt=""> -->
                    <p>RECOMMEND FOR YOU :</p>
                    <!-- <div>more</div> -->
                </div>
                <div class="cont-st-card" v-for="item in gameList" :key="item.id" @click="goDetails(item)">
                    <img :src="item.logo" alt="">
                    <p class="no-warp">{{item.title}}</p>
                    <!-- <van-rate
                    v-model="value"
                    readonly
                    :size="10"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    /> -->
                </div>
                <span v-for="item in 8" :key="item+'s'"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { 
    getGameById,
} from '@/api/games'
    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);
            // this.getRandomGame();

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(key){
                let {game,similarGames} = await getGameById({id:key});
                this.gameInfo = game;
                this.gameList = similarGames;
                console.log(this.gameInfo);
            },
            getGame(){

            },
            // getRandomGame(){
            //     this.gameList = getRandomGames(0,300,15)
            //     console.log(this.gameList);
            // },
            goDetails(item){
                window.location.href = `${window.location.origin}/details?id=${item.id}`
            }
        },
    }
</script>

<style lang="less" scoped>
.detail{
    // padding: 20px;
    box-sizing: border-box;
    .details-top{
        margin-bottom: 22px;
        .top-body{
            width: 100%;
            height: 343px;
            background: #121A27;
            div{
                margin: 0 auto;
                text-align: center;
            }
            &::before{
                content:"";
                display:table;
            } 
            .top-img{
                margin-top: 20px;
                width: 200px;
                height: 200px;
                border-radius: 24px;
                background: #000000;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .top-title{
                margin: 6px auto 23px;
                width: 106px;
                height: 22px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                font-style: normal;
            }
            .top-btn{
                width: 290px;
                height: 40px;
                background: #F04438;
                border-radius: 20px;
                cursor: pointer;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 40px;
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }
    .gamewin{
        width: 100%;
        height: calc(100ch - 50px);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .details-description{
        width: 90%;
        margin: 0 auto 15px;
        padding: 15px;
        box-sizing: border-box;
        background: #F4F4F6;
        border-radius: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #000;
        line-height: 22px;
        font-style: normal;
        .des-top{
            font-size: 16px;
        }
        .des-body{
            font-size: 14px;
        }
    }
    .cont-st{
        width: 90%;
        margin: 0 auto;
        // padding: 0 20px;
        box-sizing: border-box;
        border-radius: 20px;
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .cont-st-h{
            width: 100%;
            height: 40px;
            p{
                // width: 100%;
                // height: 40px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                // line-height: 40px;
                text-align: left;
                font-style: normal;
                // margin: 15px 0 0 15px;
            }
            div{
                margin-right: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
            }
        }
        .cont-st-card{
            margin: 7px 0;
            // padding-top: 6px;
            box-sizing: border-box;
            width: 101px;
            // height: 152px;
            // background: #FFFFFF;
            // border-radius: 11px;
            text-align: center;
            img{
                width: 101px;
                height: 101px;
                border-radius: 15px;
            }
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 14px;
                color: #000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
        span{
            width: 101px;
        }
    }
    .no-warp{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .warp-6{
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>